import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import hpInfoStore from './hpInfoStore'
import doctorInfoStore from './doctorInfoStore'
import treatmentStore from './treatmentStore'
import boardNoStore from './boardNoStore'
import registInfoStore from './registInfoStore'
import loginTypeStore from './loginTypeStore'
import appointmentStore from './appointmentStore'
import redirectUrlStore from './redirectUrlStore'

export default createStore({
    plugins: [
        createPersistedState({
            paths: ['hpInfoStore', 'doctorInfoStore', 'treatmentStore', 'boardNoStore', 'registInfoStore', 'loginTypeStore','appointmentStore','redirectUrlStore', 'testScore']
        })
    ],
    state: {
        kumcApi: {
            searchApi: { // 통합검색
                search: '/api/search/search.do',                                // 통합검색
                popword: '/api/search/popword.do',                              //인기검색어
                //recommend: '/api/search/recommend.do',                        // 연관검색
            },
            articleApi: { // 게시판 컨트롤러
                article: '/api/article/',                                       // 게시글 조회, 등록, 삭제 // 상세조회
                boardInfo: '/api/board/',                                       //게시판정보
                newsList: '/api/article/newsList'                               // 언론보도 조회
            },
            deptHpApi: { // 부서 홈페이지
                deptCd: '/api/dept/deptCd.do',                                  // deptCd 없을 경우 deptCd 조회
                hpMenu: '/api/dept/hpMenu.do',                                  // 메뉴 관리
                hpSetting: '/api/dept/hpSetting.do',                            // 메인 게시판
            },
            departmentApi: { // 부서 컨트롤러
                department: '/api/department.do',                               // 부서 목록 조회
                departmentInfo: '/api/department/info.do',                      // 부서 조회
                departmentLocation: '/api/department/location.do',              // 위치 안내
                quick: '/api/department/quick.do'                               // 퀵메뉴
            },
            appointmentApi: { // 예약 컨트롤러
                getDepartment: '/api/getDepartmentList.do',                     // 예약 진료과
                doctorList: '/api/getDoctorList.do',                            // 예약 의료진목록 조회
                doctorTime: '/api/getDoctorTime.do',                            // 진료 가능 시간표
                nameCheck: '/api/reservation/nameCheck.do',                     // 실명인증
                getPatInfo: '/api/reservation/getPatInfo.do',                   // 환자정보조회
                reservation: '/api/reservation/reserve.do',                     // 예약 목록, 입력
                reservationCancel: '/api/reservation/reserveCancel.do',         // 예약 취소
                reservationChange: '/api/reservation/reserveChange.do',         // 예약 변경
                getOutPat: '/api/getOutPatientReserve.do',                      // 외래예약정보조회
                getWhether: '/api/getReserveWhether.do',                        // 초재진여부조회
                getDoctorSchdule: '/api/getDoctorScheduleList.do',              // 예약가능스케쥴 조회
                recentReserveInfo: '/api/reservation/recentReserveInfo.do',     // 최근진료내역
                simpleReservation: '/api/reservation/simpleReservation.do',     // 간편예약, 첫방문 간편예약
                dupCheck: '/api/reserveDupCheck.do', // 예약 중복체크
            },
            doctorApi: { // 의료진 컨트롤러
                doctorCons: '/api/doctor/consonantList.do',                     // 초성
                doctorList: '/api/doctorApi.do',                                // 의료진 목록
                record: '/api/getDoctorRecordList.do',                          // 의료진 이력
                schedule: '/api/getDoctorSchedule.do',                          // 의료진 진료일정
                thesis: '/api/getDoctorThesisList.do',                          // 의료진 논문
                thesisEmr: '/api/doctor/getDoctorThesisScholarList.do',         // 의료진 논문
                mccnCd: '/api/getMccnCd.do',                                    // 센터코드 조회
                absence: '/api/getDoctorAbsence.do'                             // 의료진 부재 정보 조회
            },
            registApi: { // 회원가입 컨트롤러
                encData: '/api/user/api/getEncData.do',                         // 본인인증 encData
                encDataChk: '/api/user/api/phone/result.do',                    // 본인인증 확인 결과
                ipinEncData: '/api/user/api/getIpinEncData.do',                 // 아이핀 encData
                ipinEncDataChk: '/api/user/api/ipin/result.do',                 // 아이핀 확인 결과
                parentChk: '/api/user/api/member/parentCheck.do',               // 보호자 확인
                checkId: '/api/user/checkDuplicate.do',                         // ID 중복체크
                findId: '/api/user/findId.do',                                  // ID 찾기
                findPassword: '/api/user/findPassword.do',                      // 비밀번호 찾기
                nation: '/api/user/nationList.do',                              // 국가코드 조회
                registerReg: '/api/user/registerReg.do',                        // 회원가입
                checkDuplicateDi: '/api/user/checkDuplicateDi.do',              // 중복 가입 체크
                pwChange: '/api/user/pwChange.do',                              // 비밀번호 변경
                pwChangeSkip: '/api/user/pwChangeSkip.do',                      // 6개월 뒤 비밀번호 변경
                updateMember: '/api/user/updateMember.do',                      // 회원정보 수정
                findIdPhone: '/api/user/findIdPhone.do',                        // ID 찾기
                findPasswordPhone: '/api/user/findPasswordPhone.do',            // PW 찾기
            },
            loginApi: { // 로그인 컨트롤러
                login: '/member/login.do',                                      // 로그인
                logout: '/member/logout.do',                                    // 로그아웃
                sleep: '/member/sleep.do',                                      // 휴먼회원 처리
                sleepModify: '/member/sleepModify.do',                          // 휴먼회원 정보수정
                reAgree: '/member/reAgree.do'                                   // 개인정보 재동의
            },
            myPageApi: {
                myInfo: '/api/info.do',                                         // 회원정보 - 세션정보
                checkPrivacy: '/api/mypage/info/check.do',                      // 비밀번호 확인 프로세스
                pwChange: '/api/mypage/info/pwChange.do',                       // 비밀번호 변경
                updateInfo: '/api/mypage/info/modify.do',                       // 회원 기본 정보 수정
                carInfo: '/api/getParkInfo.do',                                 // 회원 차량 조회
                updateCarInfo: '/api/regParkInfo.do',                           // 회원 차량 정보 수정
                withdraw: '/api/mypage/info/withdraw.do',                       // 회원 탈퇴
                mainInfo : '/api/mypage/main.do',	                            //메인페이지 컨텐츠 정보
                writeList : '/api/mypage/write/list.do',                        //내가 쓴글 목록
                writeUpdate : '/api/mypage/write/modify.do',                    //내가 쓴글 수정
                writeDelete : '/api/mypage/write/delete.do',                    //내가 쓴글 삭제
                reservationList : '/api/mypage/reservation/list.do',			//예약정보 - GET
                cancelReservation : '/api/mypage/reservation/cancel.do',		//예약취소 - POST
                chartOutList : '/api/ptSchedule.do',							//일정조회 - 결제여부 - GET
                chartSchedule : '/api/schedule.do',								//일정조회 - 결제여부 - GET
                chartDrugList : '/api/drugList.do',								//일정조회 - 처방전조회 - GET
                chartExamList : '/api/healthCheckResult.do',					//검사결과조회
                payOutList : '/api/patientPayList.do',							//외래 수납 대상 정보
                payInList : '/api/hospitalPayList.do',							//입원 수납 대상 정보
                payDetail : '/api/payDetailInfo.do',                            //수납상세정보
                bookmarkList : '/api/mypage/bookmark/data.do',                  //즐겨찾기 조회
                healthList : '/api/mypage/health/list.do',                      //나의 건강정보 조회
                healthReg : '/api/mypage/health/register.do',                   //나의 건강정보 등록
                healthDel : '/api/mypage/health/delete.do',                     //나의 건강정보 삭제
                payCompleteList : '/api/payCompleteList.do',                    //결제목록 ( mcrcYn = 'Y' 인것만 표시할것 )
                payCompleteyDetail : '/api/payCompleteyDetail.do',              //결제상세정보
                searchPtInfo: '/api/searchPtInfo.do',                           //환자번호 + 생년월일로 환자정보 조회
                updateindvdlinfo: '/api/mypage/info/indvdlinfo.do',             //개인정보 수집 동의 업데이트
                getindvdlinfo: '/api/mypage/info/getindvdlinfo.do',             //개인정보 수집 동의 내역 가져오기
                getinstMemNo: '/api/mypage/info/getinstMemNo.do',               //환자번호로 instMemno 가져오기
            },
            paymentApi : {
                init : '/api/payment/init.do',
                result : '/api/payment/result.do',
                directResult : '/api/payment/directResult.do'
            },
            favoApi: { // 즐겨찾기 컨트롤러
                favoAdd: '/api/favoInfo/favoInfoAddData.do',                    // 즐겨찾기 등록
                favoDelete: '/api/favoInfo/favoInfoDelData.do'                  // 즐겨찾기 삭제
            },
            contentApi: { // 컨텐츠 컨트롤러
                stfnt: '/api/cntnt/stfnt.do',                                   // 만족도 조사
            },
            nonpaymentApi: { // 비급여 진료비용
                list: '/api/nonpayment/nonpaymentList.do',                      // 비급여진료비용 조회
                baseDt: '/api/nonpayment/getBaseDt.do',                         // 기준일 조회
            },
            bannerApi: {
                banner: '/banner/api/banner.do'
            },
            
            lookAroundApi: {
                lookAroundList: '/api/lookAroundList.do',
                lookAround: '/api/lookAround.do'
            },

            estimateApi: {  // 예상비용찾기
                diseaseList: '/api/estimate/getDiseaseList.do',                 // 질병 리스트 조회 (Surgery / procedure)
                disease: '/api/estimate/getDisease.do',                         // 질병 상세정보 조회 (Surgery / procedure)
                diseaseOperat: '/api/estimate/getDiseaseOperat.do',             // 업로드 된 엑셀데이터 조회 (수술, 검사 비용정보)
                examination: '/api/estimate/getExamination.do',                 // 검사 상세정보 조회 (Examination)
            },

            navigateApi: {  // 편리한길찾기
                locationList: '/api/navigate/getLocationList.do',               // 위치정보 조회
                pathList: '/api/navigate/getPathList.do',                       // 경로정보 조회
                pinList: '/api/navigate/getPinList.do',                         // 핀정보 조회
                lookAround: '/api/navigate/getLookAround.do',                   // 층정보 조회
            },

            popupApi: {
                popup: '/api/popup.do',                                         // 팝업 리스트 조회
            },

            phoneApi: {
                ip: '/phoneBook/getLocalIp.do',                                 // ip 조회
                search: '/phoneBook/searchPhoneBook.do',                        // 단어로 검색
                category: '/phoneBook/categorySearch.do'                        // 부서 조회
            }

        },
        title: ''
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        /**
         * 병원 domain / 병원 넘버 코드 / 병원 코드 / 다국어 타입
         */        
        hpInfoStore: hpInfoStore,        
        // 의사 정보
        doctorInfoStore: doctorInfoStore,
        // 진료과, 센터, 클리닉 타입
        treatmentStore: treatmentStore,
        // boardNo
        boardNoStore: boardNoStore,
        // 회원가입 정보
        registInfoStore: registInfoStore,
        // 로그인 시 부여될 type
        loginTypeStore: loginTypeStore,
        // 예약 관련
        appointmentStore: appointmentStore,
        // redirectURL
        redirectUrlStore: redirectUrlStore,
    }
})
