<template>
    <Spinners v-if="isLoading"></Spinners>
    <div id="skipNav" :style="{display: nav}">
        <a :href="getContainer" tabindex="0" ref="skipLink">주요 컨텐츠로 바로가기</a>
        <a :href="getHeader" tabindex="0">주 메뉴 바로가기</a>
    </div>
    <router-view />
    <LoginExtension ref="loginExtension"></LoginExtension>
</template>

<script>
//import store from './store';

//require("@/assets/resource/css/com/fullpage.css");
//require("@/assets/resource/css/com/jquery-ui.css");
//require("@/assets/resource/css/com/reset.css");
//require("@/assets/resource/css/com/slick.css");
//require("@/assets/resource/css/com/swiper-bundle.min.css");
//require("@/assets/resource/css/fonts/font.css");

//var routeNm = location.pathname;
//if(store.state.hpInfoStore.lang == 'kr' || store.state.hpInfoStore.lang == ''){
//    if(routeNm == '/' || routeNm == '/kr/index.do'){
//        require('@/assets/resource/css/pages/main.css');
//    }
//    if (!routeNm.includes('/department/center') || !routeNm.includes('/department/department') || !routeNm.includes('/department/admin') || !routeNm.includes('/department/cancerTeam') || !routeNm.includes('/department/cancerClinic')){
//        require('@/assets/resource/css/com/common.css');
//    }
//    require('@/assets/resource/css/com/style.css');
//}else{
//    require('@/assets/resource/css/en/com/common.css');
//    if(routeNm == `/${store.state.hpInfoStore.lang}/index.do`){
//        require('@/assets/resource/css/en/pages/main.css');
//    }
//    else{
//        require('@/assets/resource/css/en/pages/sub_lang.css');
//        require('@/assets/resource/css/en/pages/sub_lang_mo.css');
//    }
//    require('@/assets/resource/css/en/com/style.css');
//}

import Spinners from './components/common/Spinners.vue';
import LoginExtension from './components/login/LoginExtension.vue';

export default {
    data() {
        return {
            isLoading: false,
            urlList: [],
            unknownUrl: [
                {'url': '/kr/search.do', 'title': '통합검색'}
                ,{'url': '/kr/login/sleep.do', 'title': '휴면계정 전환'}
                ,{'url': '/kr/login/sleepModify.do', 'title': '휴면계정복원'}
                ,{'url': '/kr/login/idResolutionPhone.do', 'title': '아이디 찾기'}
                ,{'url': '/kr/login/pwInquiry.do', 'title': '비밀번호 찾기'}
                ,{'url': '/kr/login/passwordResolution.do', 'title': '비밀번호 찾기'}
                ,{'url': '/kr/login/passwordResolutionPhone.do', 'title': '비밀번호 찾기'}
                ,{'url': '/kr/login/lastPwChange.do', 'title': '비밀번호 변경'}
                ,{'url': '/kr/regist/pwChange.do', 'title': '비밀번호 변경'}
                ,{'url': '/kr/login/reAgree.do', 'title': '개인정보 재동의'}
                ,{'url': '/doctor-department/doctor/list.do', 'title': '의료진 상세페이지'}
                ,{'url': '/doctor-department/doctor/viewEmpId.do', 'title': '의료진 상세페이지'}
                ,{'url': '/doctor-department/department/list.do', 'title': '진료과 상세페이지'}
                ,{'url': '/doctor-department/center/list.do', 'title': '센터 상세페이지'}
                ,{'url': '/doctor-department/clinic/list.do', 'title': '클리닉 상세페이지'}
                ,{'url': '/kr/doctor-department/cancer-team/list.do', 'title': '암병원 상세페이지'}
                ,{'url': '/kr/doctor-department/cancer-clinic/list.do', 'title': '암병원 클리닉 상세페이지'}
                ,{'url': '/kr/appointment/appointment.do', 'title': '외래진료예약'}
                ,{'url': '/kr/appointment/appointmentDoctor.do', 'title': '외래진료예약'}
                ,{'url': '/kr/appointment/appointmentSuccess.do', 'title': '예약완료'}
                ,{'url': '/kr/mypage/dashboard.do', 'title': '마이페이지'}
                ,{'url': '/kr/mypage/reservation.do', 'title': '진료예약'}
                ,{'url': '/kr/mypage/chart/out.do', 'title': '외래결과'}
                ,{'url': '/kr/mypage/chart/drug.do', 'title': '약처방 내역'}
                ,{'url': '/kr/mypage/chart/drug/detail.do', 'title': '상세 약처방 내역'}
                ,{'url': '/kr/mypage/chart/exam.do', 'title': '검사결과'}
                ,{'url': '/kr/mypage/chart/in.do', 'title': '입퇴원내역'}
                ,{'url': '/kr/mypage/payment/out.do', 'title': '외래진료비결제'}
                ,{'url': '/kr/mypage/payment/in.do', 'title': '입원진료비결제'}
                ,{'url': '/kr/mypage/payment/outDeputy.do', 'title': '외래대리결제'}
                ,{'url': '/kr/mypage/payment/inDeputy.do', 'title': '입원대리결제'}
                ,{'url': '/kr/mypage/payment/out/list.do', 'title': '외래결제내역'}
                ,{'url': '/kr/mypage/payment/in/list.do', 'title': '입원결제내역'}
                ,{'url': '/kr/mypage/write/suggest.do', 'title': '건의합니다'}
                ,{'url': '/kr/mypage/write/thanks.do', 'title': '고맙습니다'}
                ,{'url': '/kr/mypage/healthCare.do', 'title': '나의 건강관리'}
                ,{'url': '/kr/mypage/healthCare/register/bloodPressure.do', 'title': '혈압관리'}
                ,{'url': '/kr/mypage/healthCare/register/bloodSugar.do', 'title': '혈당관리'}
                ,{'url': '/kr/mypage/healthCare/register/bodyMass.do', 'title': '체질량관리'}
                ,{'url': '/kr/mypage/bookmark/doctors.do', 'title': '관심 의료진'}
                ,{'url': '/kr/mypage/bookmark/contents.do', 'title': '관심 콘텐츠'}
                ,{'url': '/kr/mypage/info/modify.do', 'title': '개인정보수정'}
                ,{'url': '/kr/mypage/info/password.do', 'title': '비밀번호변경'}
                ,{'url': '/kr/mypage/info/car.do', 'title': '차량번호등록'}
                ,{'url': '/kr/mypage/info/withdraw.do', 'title': '회원탈퇴'}
                ,{'url': '/kr/service/rull-internet.do', 'title': '인터넷이용약관'}
                ,{'url': '/kr/service/privacy.do', 'title': '개인정보처리방침'}
                ,{'url': '/kr/service/patient-right.do', 'title': '환자권리장전'}
                ,{'url': '/kr/service/refusal-collection.do', 'title': '이메일주소 무단수집 거부'}
                ,{'url': '/kr/department/department/', 'title': '부서홈페이지'}
                ,{'url': '/estimate/', 'title': 'Find Estimated Costs'}
                ,{'url': '/navigate/navigate.do', 'title': '편리한 길찾기'}
                ,{'url': '/kr/qrCode.do', 'title': 'QR코드'}
                ,{'url': '/kr/phoneBook.do', 'title': '전화번호부'}
            ],
            nav: 'none',
            tabChk: false,
            container: '',
            header: '',
            timerCheck: true,
            
            timerValue: 900,
            timerWorker: null,
            timer: null,
        }
    },
    components: {
        Spinners,
        LoginExtension
    },
    watch: {
        $route(to, from) {
            if (to.path != from.path && from.path !== '/') {
                this.loadCss();
                this.getTitle();
                // 이동 시 스크롤 x top
                window.scrollTo(0, 0);
                // 로그인 체크
                this.loginChk();
                this.timerCheck = false;
            }

            this.tabChk = false;
            if(this.tabChk){
                window.removeEventListener('keydown', this.handleTab);
                window.removeEventListener('mousedown', this.handleTab);
            }else{
                window.addEventListener('keydown', this.handleTab);
                window.addEventListener('mousedown', this.handleTab);
            }
        },
    },
    created() {
        this.loadCss();
        this.getTitle();
        this.loginChk();

        this.timerWorker = new Worker(new URL("@/assets/resource/js/worker/timer-worker.js", import.meta.url));
        this.timerWorker.onmessage = (e) => {
            this.timerValue = e.data.seconds;
            this.timer = e.data.timer;
            if(this.$store.state.loginTypeStore.status){
                if (this.timerValue === 60) {
                    this.stopTimer();
                    this.timerCheck = true;
                    this.checkRemainTime();
                }
            }
        };

        this.axios.interceptors.request.use(
            config => {
                if (this.timer !== null) {
                    this.stopTimer();
                }

                this.timerValue = 900;

                if(this.$store.state.loginTypeStore.status && config.url != '/member/session.do'){
                    this.startTimer();
                }

                if(config.url !== '/api/info.do'){
                    this.setLoading(true);
                    this.urlList.push([config.url, config.params]);
                }
                return config;
            },
            error => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        ),
        this.axios.interceptors.response.use(
            response => {
                if(response.config.url !== '/api/info.do'){
                    if(JSON.stringify(this.urlList[this.urlList.length - 1]) == JSON.stringify([response.config.url, response.config.params])) {
                        this.setLoading(false);
                    }                
                }
                return response;
            },
            error => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        )
    },
    computed: {
        getContainer() {
            return this.$route.fullPath.replaceAll('#container', '').replaceAll('#header', '') + '#container';
        },
        getHeader() {
            return this.$route.fullPath.replaceAll('#container', '').replaceAll('#header', '') + '#header';
        },
    },
    methods: {
        startTimer() {
            this.timerWorker.postMessage('start');
        },
        stopTimer() {
            this.timerWorker.postMessage('stop');
        },
        openLoginExtension(){
            document.querySelector('body').classList.add('on');
            document.querySelector('#loginExtension').style.display = "block";
            $('#loginExtension').attr("tabindex", "0").focus();
        },
        checkRemainTime(){
            this.axios.get('/member/session.do')
                .then(res => {
                    const status = res.data.status;
                    const lastTime = res.data.last;

                    let diffTime = null;
                    if (status && lastTime != null) {
                        diffTime = Math.ceil((new Date() - new Date(lastTime)) / 1000);
                    }

                    if (diffTime != null) {
                        if (diffTime < 840) {
                            const check = setInterval(() => {
                                if(this.timerCheck) {
                                    diffTime++;
                                    if (diffTime >= 840) {
                                        clearInterval(check);
                                        this.checkRemainTime();
                                    }
                                }else{
                                    clearInterval(check);
                                }
                            }, 1000);
                        } else {
                            this.$refs.loginExtension.timer = (900 - diffTime - 2);
                            this.$refs.loginExtension.doTimer();
                            this.openLoginExtension();
                        }
                    }
                })
        },
        handleTab(event){
            if (this.tabChk || this.$route.hash.includes('#container') || this.$route.hash.includes('#header')) {
                return;
            }
            if (event.key == 'Tab') {
                event.preventDefault();
                this.$refs.skipLink.focus();
            }
            this.tabChk = true;
        },
        loadCss(){
            var routeNm = location.pathname;
            // 국문
            if(this.$store.state.hpInfoStore.lang == 'kr'){
                // 메인페이지인 경우
                if(routeNm == '/' || routeNm == '/kr/index.do'){
                    import('@/assets/resource/css/pages/main.css');
                }
                // 부서홈페이지가 아닌 경우
                if (!routeNm.includes('/department/center') || !routeNm.includes('/department/department') || !routeNm.includes('/department/admin') || !routeNm.includes('/department/cancerTeam') || !routeNm.includes('/department/cancerClinic')){
                    import('@/assets/resource/css/com/common.css');
                }
                import('@/assets/resource/css/com/style.css');
            }
            // 영문
            else{
                import('@/assets/resource/css/en/com/common.css');
                // import('@/assets/resource/css/en/com/common_sub.css');
                // 메인페이지인 경우
                if(routeNm == `/${this.$store.state.hpInfoStore.lang}/index.do`){
                    import('@/assets/resource/css/en/pages/main.css');
                }
                // 메인페이지가 아닌 경우
                else{
                    import('@/assets/resource/css/en/pages/sub_lang.css');
                    import('@/assets/resource/css/en/pages/sub_lang_mo.css');
                }
                import('@/assets/resource/css/en/com/style.css');
            }
            setTimeout(() => {
                this.nav = 'block';
            }, 200);
        },
        setLoading(bool){
            this.isLoading = bool;
        },
        createCss(item) {
            var routeNm = location.pathname;
            var link = document.head.getElementsByTagName('link');
            if (!routeNm.includes('/department/')) {
                setTimeout(() => {
                    //$this.hpCd
                    const head = document.getElementsByTagName("head")[0];
                    for (let i of item) {
                        var link = document.createElement("link");
                        link.setAttribute("rel", "stylesheet");
                        link.setAttribute("href", i);
                        head.appendChild(link);
                    }
                }, 200);
            } else {
                for (let i = 1; i < link.length; i++) {
                    if(link[i].href.includes("/resource/css/com/common.css")) link[i].remove();
                }
            }
        },
        loginChk() {
            // 로그인을 하면 true
            if (this.$store.state.loginTypeStore.status) {
                console.log("login 감지");
                // 일정 시간 이후 status 변경(session time > status time)
                this.$store.dispatch("setLoginStatus", true);
                // info.do 호출로 session 확인
                this.axios
                    .get(this.$store.state.kumcApi.myPageApi.myInfo)
                    .then((res) => {
                        // info.do 가 호출가능하므로 갱신
                        if (res.data != "") {
                            this.$store.commit("getLoginStatus", true);
                            this.$store.dispatch("setLoginStatus", true);
                            // info.do 가 호출불가능하므로 삭제
                        } else {
                            this.$store.commit("getLoginStatus", false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.axios
                    .get(this.$store.state.kumcApi.myPageApi.myInfo)
                    .then((res) => {
                        if (res.data != "") {
                            this.$store.commit("getLoginStatus", true);
                            this.$store.dispatch("setLoginStatus", true);
                        } else {
                            this.$store.commit("getLoginStatus", false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        getTitle() {
            let depth = [];
            let depth1 = [];
            let depth2 = [];
            let depth3 = [];
            let depth4 = [];
            let depth5 = [];
            let title = '';
            let route = location.pathname;
            let routeSplit = route.split('/');
            let routeLen = routeSplit.length;
            if (routeSplit[routeLen - 1] == 'view.do') {
                routeSplit[routeLen - 1] = 'list.do';
                route = routeSplit.join('/');
            }
            
            for (let i = 0; i < this.menuList.length; i++) {
                if (this.menuList[i].depth == 1) {
                    depth1.push(this.menuList[i]);
                }
                if (this.menuList[i].depth == 2) {
                    depth2.push(this.menuList[i]);
                }
                if (this.menuList[i].depth == 3) {
                    depth3.push(this.menuList[i]);
                }
                if (this.menuList[i].depth == 4) {
                    depth4.push(this.menuList[i]);
                }
                if (this.menuList[i].depth == 5) {
                    depth5.push(this.menuList[i]);
                }

                if(route == this.menuList[i].menuUrl){
                    depth.push(this.menuList[i].depth);
                }
            }

            let locaNo = Math.max(...depth);
            let fourNo = 0;

            // 4depth를 넘어가는 경우(4depth name 사용)
            if(locaNo > 4){
                for(let i of depth5){
                    if(route == i.menuUrl) fourNo = i.parentMenuNo;
                }
                depth4.forEach((item, idx) => {
                    if(fourNo == item.menuNo){
                        title = item.menuName;
                    }
                })
            } else {
                depth4.forEach((item, idx) => {
                    if(route == item.menuNo){
                        title = item.menuName;
                    }
                })
                if(title == '' || title == null || title == undefined){
                    depth3.forEach((item, idx) => {
                        if(route == item.menuUrl){
                            title = item.menuName;
                        }
                    })
                }
                if(title == '' || title == null || title == undefined){
                    depth2.forEach((item, idx) => {
                        if(route == item.menuUrl){
                            title = item.menuName;
                        }
                    })
                }
            }
            document.title = title;
            const instNo = this.$store.state.hpInfoStore.instNo;
            
            if(title == ''){
                for(let i of this.unknownUrl){
                    if(route.indexOf(i.url) >= 0){
                        document.title = i.title;
                        break;
                    }else{
                        if(instNo == 1){
                            document.title = "고려대학교 안암병원";
                        }else if(instNo == 2){
                            document.title = "고려대학교 구로병원";
                        }else if(instNo == 3){
                            document.title = "고려대학교 안산병원";
                        }
                    }
                }
            }
            this.$store.state.title = title;
        },
    },
};
</script>

<style>
/*@import '@/assets/resource/css/com/common.css';*/
@import "@/assets/resource/css/com/fullpage.css";
@import "@/assets/resource/css/com/jquery-ui.css";

@import "@/assets/resource/css/com/reset.css";
@import "@/assets/resource/css/com/slick.css";
/*@import '@/assets/resource/css/com/style.css';*/
@import "@/assets/resource/css/com/swiper-bundle.min.css";
/* @import '@/assets/resource/css/com/media_style.css'; */
@import "@/assets/resource/css/fonts/font.css";

/* 221111 박재민 미디어쿼리 개별페이지 등록 */
</style>