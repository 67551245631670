<template>
    <div id="q-app">
        <div class="q-pa-md q-gutter-xs">
            <div class="q-gutter-md row justify-center" style="font-size: 2em">
                <q-spinner-dots style="font-size: 15vw; color: #a73b59; z-index: 9999;"></q-spinner-dots>
            </div>
        </div>
    </div>
    <div id="loading_back"></div>
</template>

<style scoped>
#q-app {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#loading_back {  
    position:fixed;
    width: 100vw;
    height: 100vh;
    left:0;
    top:0;
    z-index:1;  
    background-color:white;
    opacity: 0.5;
    z-index: 9998;
}
</style>