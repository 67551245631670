import store from "@/store";

const loginTypeStore = {
    state: {
        status: false,
        showMypageNameCheckModal: true       //마이페이지에서 실명확인 팝업 노출 여부 처리, 최최 1회만 노출
    },
    mutations: {
        getLoginStatus: function (state, status) {
            state.status = status;
        },
        getShowMypageNameCheckModal: function (state, status) {
            state.showMypageNameCheckModal = status;
        },
    },
    actions: {
        // 로그인 이후 일정 시간 지나면 status 값 변경
        setLoginStatus({commit}, status){
            if(store.state.loginTypeStore.status == true){
                commit('getLoginStatus', status);
                setTimeout(() => {
                    commit('getLoginStatus', false);
                    console.log('true -> false');
                }, 1790000);
            }
        }
    }
}

export default loginTypeStore;