const appointmentStore = {
    state: {
        mdrpNo: '',
        mcdpCd: '',
        empId: '',
        patNo: '',
        ptntNm: '',
        email: '',
        hpNo: '',
        msg: '',
        mdcrYmd: '',
        mdcrDt: '',
        deptNm:'',
        mccnNm:'',
        doctorNm:'',
        reservationDate:'',
        reservationTime:'',
        item: {},
    },
    mutations: {
        getItem: function (state, item) {
            state.item = item;
        },
    }
}

export default appointmentStore