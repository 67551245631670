import axios from "axios";
import store from "@/store"

const axiosInstance = axios.create();

// 요청 interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // 로그인 확인용 flag
        //if (store.state.loginTypeStore.flag == true) {
        //    // 로그인을 하면 true
        //    if (store.state.loginTypeStore.status == true) {
        //        console.log('login 감지')
        //        // 일정 시간 이후 status 변경(session time > status time)
        //        store.dispatch('setLoginStatus', true);
        //        // setLoginStatus으로 인하여 false로 변경
        //    } else if (store.state.loginTypeStore.status == false) {
        //        axios
        //            .get(store.state.kumcApi.myPageApi.myInfo)
        //            .then(res => {
        //                console.log(res.data)
        //                // info.do 가 호출가능하므로 갱신
        //                if (res.data != '') {
        //                    store.commit("getLoginStatus", true);
        //                    store.dispatch('setLoginStatus', true);
        //                    console.log('갱신');
        //                    // info.do 가 호출불가능하므로 삭제
        //                } else {
        //                    store.commit("getLoginStatus", false);
        //                    store.commit("setLoginStatus", false);
        //                    console.log('삭제');
        //                }
        //            })
        //            .catch(err => {
        //                console.log(err)
        //            });
        //    }
        //}
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
)
// 응답 interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        var checkExist = setInterval(() => {
            var gotop = document.querySelector('.gotop');
            var target = document.querySelector('.footer');
            if (gotop != null && target != null) {
                var targetHei = target.offsetHeight;
                var bodyHei = document.body.scrollHeight;
                var scrollHei = document.documentElement.clientHeight;
                if (targetHei != null) {
                    document.addEventListener('scroll', () => {
                        if (bodyHei - scrollHei - targetHei < window.scrollY) {
                            gotop.classList.add('on')
                        } else {
                            gotop.classList.remove('on')
                        }
                    })
                    clearInterval(checkExist)
                }
                setTimeout(() => {
                    clearInterval(checkExist)
                }, 1000);
            }
        }, 100);
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
)
export default axiosInstance