<template>
    <div class="modal_outer" id="loginExtension" v-show="onModal" >
        <div class="modal_backdrop"></div>
        <div class="modal_wrap">
            <div class="modal_top">
                <em :class="`logo ${$store.state.hpInfoStore.hpCd}`" :title="`고려대학교${$store.state.hpInfoStore.hosNm}병원 로고`"></em>
            </div>
            <div class="modal_con">
                <div class="modal_inner">
                    <div class="prognosis_wrap mt40">
                        <h3 class="list_s">자동 로그아웃 안내</h3>
                    </div>
                    <div class="gradientbox" style="padding:0;" tabindex="0">
                        <div class="gradient_top"></div>
                        <div class="gradientbox" style="padding:0;" tabindex="0">
                            <div class="gradient_con" style="text-align: center;">
                                <p style="font-size: 24px; color: #a73b59;">로그아웃까지 남은 시간</p>
                                <br>
                                <p id="remainTime" style="font-size: 42px; font-weight: 700; color: black;">{{timer}}초</p>
                                <br>
                                <p>장시간 활동이 없어 자동으로 로그아웃 됩니다.</p>
                                <p>계속 이용을 원하시면 로그인 연장 버튼을 클릭해 주세요.</p>
                            </div>
                        </div>
                    </div>
                    <div class="btn_wrap col_2 f_center mt30">
                        <a href="javascript:;" title="로그아웃" class="btn btn_line main" @click="logout"><span>로그아웃</span></a>
                        <a href="javascript:;" title="로그인 연장하기" class="btn btn_main" @click="doLoginExtension" @focusout="focusout"><span>로그인 연장</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            onModal: false,
            timer: null,
            detected: false,
        }
    },
    methods: {
        focusout(){
            $("#loginExtension").attr("tabindex", "0").focus();
        },
        doLoginExtension(){
            this.axios.get(this.$store.state.kumcApi.myPageApi.myInfo)
            .then(res => {
                alert('로그인이 연장되었습니다.');
                document.querySelector('body').classList.remove('on');
                document.querySelector('#loginExtension').style.display = 'none';
                this.onModal = false;
                this.detected = true;
            });
        },
        logout(){
            this.axios
                .get(this.$store.state.kumcApi.loginApi.logout)
                .then((res) => {
                    console.log(res);
                })
                .catch(err => {
                    console.log(err);
                });
            this.$store.commit("getLoginStatus", false);
            location.reload();
        },
        doTimer(){
            const timer = setInterval(() => {
                this.timer--;
                if(this.timer == 0) this.logout();
                if(this.detected) clearInterval(timer);
            }, 1000);
        }
    },
}

</script>