const redirectUrlStore = {
    state: {
        redirectUrl: '',
    },
    mutations: {
        getRedirectUrl: function (state, redirectUrl) {
            state.redirectUrl = redirectUrl;
        },
    }
}

export default redirectUrlStore