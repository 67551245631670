import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './assets/resource/js/axios/axiosInterceptor'
import MasonryWall from '@yeger/vue-masonry-wall'
import globalMethods from './assets/resource/js/global/global'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { Quasar } from 'quasar'
//import '@quasar/extras/material-icons/material-icons.css'
//import 'quasar/dist/quasar.css'
if(window.Kakao){
    window.Kakao.init("405e50f67ff556143e0939597260b510");
}

// 병원코드, 다국어, 병원No store
if(location.hostname.split('.')[0].includes('dev')){
    store.commit("getHost", location.hostname.split('.')[0].replace('dev', ''));
}else if(location.hostname.split('.')[0].includes('t1')){
    store.commit("getHost", location.hostname.split('.')[0].replace('t1', ''));
}else{
    store.commit("getHost", location.hostname.split('.')[0]);
}

var hpCd = '';
if(location.hostname.split('.')[0].includes('anam')){
    hpCd = 'AA'
}else if(location.hostname.split('.')[0].includes('guro')){
    hpCd = 'GR'
}else if(location.hostname.split('.')[0].includes('ansan')){
    hpCd = 'AS'
}

store.commit("getHpCd", hpCd);
store.commit("getLang", location.pathname.split('/')[1]);

var instNo = '';
if(location.hostname.split('.')[0].includes('anam')){
    instNo = 1
}else if(location.hostname.split('.')[0].includes('guro')){
    instNo = 2
}else if(location.hostname.split('.')[0].includes('ansan')){
    instNo = 3
}
store.commit("getInstNo", instNo);

// 웹접근성 - 휴먼랭귀지 변경
function langChange(lang){
    setInterval(() => {
        if(document.documentElement.lang != lang){
            document.documentElement.lang = lang;
            clearInterval(langChange(lang));
        }
    }, 100);
    setTimeout(() => {
        clearInterval(langChange(lang));
    }, 1000);
}

if(store.state.hpInfoStore.lang == '' || store.state.hpInfoStore.lang == 'kr'){
    //document.documentElement.lang = 'ko';
    langChange('ko');
}else{
    //document.documentElement.lang = store.state.hpInfoStore.lang;
    langChange(store.state.hpInfoStore.lang);
}

var hosNm = '';
if(location.hostname.split('.')[0].includes('anam')){
    hosNm = '안암'
}else if(location.hostname.split('.')[0].includes('guro')){
    hosNm = '구로'
}else if(location.hostname.split('.')[0].includes('ansan')){
    hosNm = '안산'
}
store.commit("getHosNm", hosNm);

const app = createApp(App);
app.config.globalProperties.axios = axios;

var sync = 'N';
axios.get('/menu.do', {
        headers: {
            'Cache-Control': 'no-store',
            'Pragma': 'no-store',
            'Expires': '0',
        },
        params: {
            instNo: store.state.hpInfoStore.instNo,
            lang: store.state.hpInfoStore.lang
        }
    })
    .then((res) => {
        app.config.globalProperties.menuList = res.data;
        sync = 'Y';
    })
    .catch(err => console.log(err));


var interval = setInterval(function(){
    if(sync == 'Y'){
        app.use(store).use(router).use(MasonryWall).use(globalMethods).use(Datepicker).use(Quasar).mount('#app');
        clearInterval(interval);
    }
},100);
store.state.hpInfoStore.lang == 'zh' ? document.getElementById('app').classList.add('zh_font') : document.getElementById('app').classList.remove('zh_font');
