import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { isLoginCheck, isNotLoginCheck } from './loginGuard';

const routes = [
    // main page
    {
        path: '/:lang/index.do',
        name: 'Main',
        component: () => {
            switch (store.state.hpInfoStore.host) {
                case "anam":
                    if (store.state.hpInfoStore.lang === 'kr') {
                        return import('../views/main/anam/AnamKrMainView.vue');
                    } else {
                        return import('../views/main/anam/AnamEnMainView.vue');
                    }
                case "guro":
                    if (store.state.hpInfoStore.lang === 'kr') {
                        return import('../views/main/guro/GuroKrMainView.vue');
                    } else {
                        return import('../views/main/guro/GuroEnMainView.vue');
                    }
                case "ansan":
                    if (store.state.hpInfoStore.lang === 'kr') {
                        return import('../views/main/ansan/AnsanKrMainView.vue');
                    } else {
                        return import('../views/main/ansan/AnsanEnMainView.vue');
                    }
            }
        },
        children: [{
            path: '/',
            component: () => {
                switch (store.state.hpInfoStore.host) {
                    case "anam":
                        if (store.state.hpInfoStore.lang === 'kr') {
                            return import('../views/main/anam/AnamKrMainView.vue');
                        } else {
                            return import('../views/main/anam/AnamEnMainView.vue');
                        }
                    case "guro":
                        if (store.state.hpInfoStore.lang === 'kr') {
                            return import('../views/main/guro/GuroKrMainView.vue');
                        } else {
                            return import('../views/main/guro/GuroEnMainView.vue');
                        }
                    case "ansan":
                        if (store.state.hpInfoStore.lang === 'kr') {
                            return import('../views/main/ansan/AnsanKrMainView.vue');
                        } else {
                            return import('../views/main/ansan/AnsanEnMainView.vue');
                        }
                }
            }
        }],
        // main page path가 없으면 '/kr/index.do'로 호출
        beforeEnter: (to, from, next) => {
            if (window.location.pathname == '/') {
                window.location.pathname = '/kr/index.do';
            } else {
                return next();
            }
        }
    },

    // 통합검색
    {
        path: '/kr/search.do',
        name: 'search',
        component: () => import('../views/search/SearchView.vue'),
    },

    // 로그인
    {
        path: '/kr/login/login.do',
        name: 'login',
        component: () => import('../views/login/LoginView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '로그인');
        }
    },
    
    // 휴면회원
    {
        path: '/kr/login/sleep.do',
        name: 'sleep',
        component: () => import('../views/login/SleepView.vue'),
    },
    // 휴면회원 수정
    {
        path: '/kr/login/sleepModify.do',
        name: 'sleepModify',
        component: () => import('../views/login/SleepModifyView.vue'),
    },

    {
        path: '/kr/login/idInquiry.do',
        name: 'idInquiry',
        component: () => import('../views/login/IdInquiryView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '아이디 찾기');
        }
    },
    {
        path: '/kr/login/idResolution.do',
        name: 'idResolution',
        component: () => import('../views/login/IdResolutionView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n아이디 찾기을(를) 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishEmail == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },
    {
        path: '/kr/login/idResolutionPhone.do',
        name: 'idResolutionPhone',
        component: () => import('../views/login/IdResolutionPhoneView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert('로그인이 되어있습니다.\n아이디 찾기을(를) 하려면 로그아웃을 해주시길 바랍니다.');
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.phone == '') {
                alert('잘못된 접근입니다.');
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },
    {
        path: '/kr/login/pwInquiry.do',
        name: 'pwInquiry',
        component: () => import('../views/login/PwInquiryView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '비밀번호 찾기');
        }
    },
    {
        path: '/kr/login/passwordResolution.do',
        name: 'passwordResolution',
        component: () => import('../views/login/PasswordResolutionView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n비밀번호 찾기을(를) 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishEmail == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },
    {
        path: '/kr/login/passwordResolutionPhone.do',
        name: 'passwordResolutionPhone', 
        component: () => import('../views/login/PasswordResolutionPhoneView.vue'),
        // 로그인 성공 시 로그인 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n비밀번호 찾기을(를) 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishHpNo == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },
    // 비밀번호 변경 페이지(n개월마다)
    {
        path: '/kr/login/lastPwChange.do',
        name: 'lastPwChange',
        component: () => import('../views/login/lastPwChangeView.vue'),
    },

    // 회원가입
    {
        path: '/kr/regist/select.do',
        name: 'select',
        component: () => import('../views/regist/SelectView.vue'),
        // 로그인 성공 시 회원가입 페이지 이동 막기
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    {
        path: '/kr/regist/agree.do',
        name: 'agree',
        component: () => import('../views/regist/AgreeView.vue'),
        // 로그인 성공 시 회원가입 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    {
        path: '/kr/regist/cert.do',
        name: 'cert',
        component: () => import('../views/regist/CertificationView.vue'),
        // 로그인 성공 시 회원가입 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    {
        path: '/kr/regist/register.do',
        name: 'register',
        component: () => import('../views/regist/RegisterView.vue'),
        // 로그인 성공 시 회원가입 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    {
        path: '/kr/regist/complete.do',
        name: 'complete',
        component: () => import('../views/regist/CompleteView.vue'),
        // 로그인 성공 시 회원가입 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    {
        path: '/kr/regist/pwChange.do',
        name: 'pwChange',
        component: () => import('../views/regist/PwChangeView.vue'),
        // 로그인 성공 시 회원가입 페이지 이동 막기 , url 직접 입력 방지
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },

    // 개인정보 재동의(2년마다)
    {
        path: '/kr/login/reAgree.do',
        name: 'reAgree',
        component: () => import('../views/regist/ReAgreeView.vue'),
    },


    // 의료진/진료과
    // 의료진
    {
        path: '/:lang/doctor-department/doctor.do',
        name: 'doctor',
        component: () => import('../views/doctorTreatment/DoctorView.vue')
    },
    {
        path: '/:lang/doctor-department/doctor/view.do',
        name: 'doctorDetail',
        component: () => import('../views/doctorTreatment/DoctorDetailView.vue')
    },
    {
        path: '/:lang/doctor-department/doctor/viewEmpId.do',
        name: 'doctorEmpIdDetail',
        component: () => import('../views/doctorTreatment/DoctorEmpIdDetailView.vue')
    },
    // 진료과
    {
        path: '/:lang/doctor-department/department.do',
        name: 'treatment',
        component: () => import('../views/doctorTreatment/TreatmentView.vue')
    },
    // 센터
    {
        path: '/:lang/doctor-department/center.do',
        name: 'center',
        component: () => import('../views/doctorTreatment/CenterView.vue')
    },
    // 클리닉
    {
        path: '/kr/doctor-department/clinic.do',
        name: 'clinic',
        component: () => import('../views/doctorTreatment/ClinicView.vue')
    },
    // 진료과 통합 상세페이지
    {
        path: '/:lang/doctor-department/department/view.do',
        name: 'departmentDetail',
        component: () => import('../views/doctorTreatment/DepartmentDetailView.vue'),
        alias: [
            '/:lang/doctor-department/center/view.do',
            '/:lang/doctor-department/clinic/view.do',
        ]
    },
    // 무수혈 게시판 detail
    //{
    //    path: '/:lang/doctor-department/center/blood/view.do',
    //    name: 'boardDetail',
    //    component: () => import('../views/board/BoardDetailView.vue')
    //},
    // 암병원 협진팀
    {
        path: '/kr/doctor-department/cancer-team.do',
        name: 'cancerTeam',
        component: () => import('../views/doctorTreatment/CancerTeamView.vue')
    },
    // 암병원 클리닉
    {
        path: '/kr/doctor-department/cancer-clinic.do',
        name: 'cancerClinic',
        component: () => import('../views/doctorTreatment/CancerClinicView.vue')
    },
    // 암병원 통합 상세페이지
    {
        path: '/kr/doctor-department/cancer-team/view.do',
        name: 'cancerDetail',
        component: () => import('../views/doctorTreatment/CancerDetailView.vue'),
        alias: [
            '/:lang/doctor-department/cancer-clinic/view.do'
        ]
    },

    // 예약
    {
        path: '/kr/appointment/appointment.do',
        name: 'appointment',
        component: () => import('../views/appointment/AppointmentView.vue'),
        beforeEnter (to, from, next) {
            isNotLoginCheck(to, from, next);
        }
    },
    {
        path: '/kr/appointment/appointmentDoctor.do',
        name: 'appointmentDoctor',
        component: () => import('../views/appointment/AppointmentDoctorView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    {
        path: '/kr/appointment/appointmentSuccess.do',
        name: 'appointmentSuccess',
        component: () => import('../views/appointment/AppointmentSuccessView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },

    // 병원소개 - 연혁
    {
        path: '/:lang/introduction/introduction/history.do',
        name: 'history',
        component: () => import('../views/history/HistoryView.vue')
    },

    // 본인인증
    {
        path: '/member/chk_success.do',
        name: 'checkSuccess',
        component: () => import('../views/common/CheckSuccess.vue')
    },

    // 통합
    // board, contents
    {
        path: '/:lang/:path1/:path2.do',
        name: 'kumc',
        component: () => import('../views/merge/MergeView.vue'),
        children: [{
            path: '/:lang/:path1/:path2/:path3.do',
            component: () => import('../views/merge/MergeView.vue')
        }],
        alias: [
            '/:lang/:path1/:path2/:path3/:path4.do',
            '/:lang/:path1/:path2/:path3/:path4/:path5.do',
            '/:lang/:path1/:path2/:path3/:path4/:path5/:path6.do',
        ]
    },
    // 게시판 detail
    {
        path: '/:lang/:path1/view.do',
        name: 'boardDetail',
        component: () => import('../views/board/BoardDetailView.vue'),
        alias: [
            '/:lang/doctor-department/center/blood/view.do'
        ]
    },
    //언론보도
    {
        path: '/:lang/introduction/news/press-report.do',
        name: 'report',
        component: () => import('../views/board/ReportBoardView.vue')
    },

    // 고객의 소리
    {
        path: '/kr/service-guide/client/tendinousWrite.do',
        name: 'tendinousWrite',
        component: () => import('../views/board/tendinousWrite.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    {
        path: '/kr/service-guide/client/thanksWrite.do',
        name: 'thanksWrite',
        component: () => import('../views/board/thanksWrite.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },

    
    // 비급여진료비용
    {
        path: '/:lang/service-guide/med-information/nonPayment.do',
        name: 'nonPayment',
        component: () => import('../views/nonPayment/NonPayment.vue'),
    },
    
    
    
    /**
     * 마이페이지
     */
    //DashBoard
    {
        path: '/kr/mypage/dashboard.do',
        name: 'mypage-dashboard',
        component: () => import('../views/mypage/dashboardView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    //예약조회
    {
        path: '/kr/mypage/reservation.do',
        name: 'mypage-reservation',
        component: () => import('../views/mypage/reservationView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    //결과조회 - 외래
    {
        path: '/kr/mypage/chart/out.do',
        name: 'mypage-chart-out',
        component: () => import('../views/mypage/chartOutView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //결과조회 - 약처방
    {
        path: '/kr/mypage/chart/drug.do',
        name: 'mypage-chart-drug',
        component: () => import('../views/mypage/chartDrugView.vue'),
        children: [{
            path: '/kr/mypage/chart/drug/detail.do',
            component: () => import('../views/mypage/chartDrugView.vue')
        }],
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //결과조회 - 검사결과
    {
        path: '/kr/mypage/chart/exam.do',
        name: 'mypage-chart-exam',
        component: () => import('../views/mypage/chartExamView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    //결과조회 - 입/퇴원
    {
        path: '/kr/mypage/chart/in.do',
        name: 'mypage-chart-in',
        component: () => import('../views/mypage/chartInView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    //진료비결제 - 외래
    {
        path: '/kr/mypage/payment/out.do',
        name: 'mypage-payment-out',
        component: () => import('../views/mypage/paymentOutView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //진료비결제  - 입원
    {
        path: '/kr/mypage/payment/in.do',
        name: 'mypage-payment-in',
        component: () => import('../views/mypage/paymentInView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //진료비결제  - 외래 대리
    {
        path: '/kr/mypage/payment/outDeputy.do',
        name: 'mypage-payment-out-deputy',
        component: () => import('../views/mypage/paymentOutDeputyView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //진료비결제  - 입원대리
    {
        path: '/kr/mypage/payment/inDeputy.do',
        name: 'mypage-payment-in-deputy',
        component: () => import('../views/mypage/paymentInDeputyView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //결제내역
    //out : 외래
    //in : 입원
    {
        path: '/kr/mypage/payment/:pageType/list.do',
        name: 'mypage-payment-complete-list',
        component: () => import('../views/mypage/paymentCompleteListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //나의작성글 - 건의
    {
        path: '/kr/mypage/write/suggest.do',
        name: 'mypage-write-suggest',
        component: () => import('../views/mypage/writeSuggestView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //나의작성글 - 감사
    {
        path: '/kr/mypage/write/thanks.do',
        name: 'mypage-write-thanks',
        component: () => import('../views/mypage/writeThanksView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },   
    //나의건강관리 - 건강관리
    {
        path: '/kr/mypage/healthCare.do',
        name: 'mypage-healthCare',
        component: () => import('../views/mypage/healthCareView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    //나의건강관리 - 건강관리 등록
    // bloodPressure    : 혈압
    // bloodSugar       : 혈당
    // bodyMass         : 체질량
    {
        path: '/kr/mypage/healthCare/register/:pageType.do',
        name: 'mypage-healthCare-register',
        component: () => import('../views/mypage/healthCareRegView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    //환자번호 - 나의건강관리 - 건강관리 등록
    // bloodPressure    : 혈압
    // bloodSugar       : 혈당
    // bodyMass         : 체질량
    {
        path: '/kr/patient/healthCare/register/:pageType.do',
        name: 'patient-healthCare-register',
        component: () => import('../views/mypage/patienthealthCareRegView.vue'),
    }, 
    //환자번호 - 건강관리 - 건강관리
    {
        path: '/kr/patient/healthCare.do',
        name: 'patient-healthCare',
        component: () => import('../views/mypage/patientHealthCareView.vue'),
    }, 
    //즐겨찾기 - 의료진
    {
        path: '/kr/mypage/bookmark/doctors.do',
        name: 'mypage-bookmark-doctor',
        component: () => import('../views/mypage/bookmarkDoctorView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //즐겨찾기 - 컨텐츠
    {
        path: '/kr/mypage/bookmark/contents.do',
        name: 'mypage-bookmark-content',
        component: () => import('../views/mypage/bookmarkContentView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //회원정보 - 개인정보수정
    {
        path: '/kr/mypage/info/modify.do',
        name: 'mypage-info-modify',
        component: () => import('../views/mypage/infoModifyView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //회원정보 - 비밀번호변경
    {
        path: '/kr/mypage/info/password.do',
        name: 'mypage-info-password',
        component: () => import('../views/mypage/infoPasswordView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    //회원정보 - 차량번호등록
    {
        path: '/kr/mypage/info/car.do',
        name: 'mypage-info-car',
        component: () => import('../views/mypage/infoCarView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    //회원정보 - 회원탈퇴
    {
        path: '/kr/mypage/info/withdraw.do',
        name: 'mypage-info-withdraw',
        component: () => import('../views/mypage/infoWithdrawView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },       

    // footer service
    // 인터넷이용약관
    {
        path: '/kr/service/rull-internet.do',
        name: 'rull-internet',
        component: () => import('../views/service/RullInternetView.vue'),
    },
    // 개인정보처리방침
    {
        path: '/kr/service/privacy.do',
        name: 'privacy',
        component: () => import('../views/service/PrivacyView.vue'),
    },
    // 환자권리장전
    {
        path: '/kr/service/patient-right.do',
        name: 'patient-right',
        component: () => import('../views/service/PatientRightView.vue'),
    },
    // 이메일주소 무단수집 거절
    {
        path: '/kr/service/refusal-collection.do',
        name: 'refusal-collection',
        component: () => import('../views/service/RefusalCollectionView.vue'),
    },

    // 층별안내(위치안내)
    {
        path: '/:lang/service-guide/floor-guide.do',
        name: 'floor-guide',
        component: () => import('../views/service/FloorGuideView.vue'),
    },
    // 2층층별안내(위치안내)
    {
        path: '/:lang/service-guide/floor-guide-r2.do',
        name: 'floor-guide2',
        component: () => import('../views/service/FloorGuideView.vue'),
    }, 
    // 층별안내(테스트)
    // {
    //     path: '/:lang/service-guide/floor-guide-test.do',
    //     name: 'floor-guide',
    //     component: () => import('../views/service/FloorGuideView.vue'),
    // },    
    // 층별안내(안암 키오스크용)
    {
        path: '/:lang/service-guide/floor-guide-KIOSKAA.do',
        name: 'floor-guide-kiosk',
        component: () => import('../views/service/FloorGuideViewKIOSKAA.vue'),
    },

    //부서템플릿
    //정보전달
    {
        path: '/kr/department/department/infoTrans/:path1.do',
        name: 'dept_infoTrans',
        component: () => import('../views/department/infoTrans/infomationTransferView.vue'),
        alias: [
            '/kr/department/center/infoTrans/:path1.do',
            '/kr/department/cancerTeam/infoTrans/:path1.do',
            '/kr/department/cancerClinic/infoTrans/:path1.do',
            '/kr/department/admin/infoTrans/:path1.do',
        ]
    },
    //퀵메뉴
    {
        path: '/kr/department/department/quickMenu/:path1.do',
        name: 'dept_quickMenu',
        component: () => import('../views/department/quickMenu/quickMenuView.vue'),
        alias: [
            '/kr/department/center/quickMenu/:path1.do',
            '/kr/department/cancerTeam/quickMenu/:path1.do',
            '/kr/department/cancerClinic/quickMenu/:path1.do',
            '/kr/department/admin/quickMenu/:path1.do',
        ]
    },
    //홍보
    {
        path: '/kr/department/department/promotion/:path1.do',
        name: 'dept_promotion',
        component: () => import('../views/department/promotion/promotionView.vue'),
        alias: [
            '/kr/department/center/promotion/:path1.do',
            '/kr/department/cancerTeam/promotion/:path1.do',
            '/kr/department/cancerClinic/promotion/:path1.do',
            '/kr/department/admin/promotion/:path1.do',
        ]
    },
    // 콘텐츠
    {
        path: '/kr/department/department/:path1.do',
        name: 'dept_content',
        component: () => import('../views/department/content/deptContentsView.vue'),
        alias: [
            '/kr/department/center/:path1.do',
            '/kr/department/cancerTeam/:path1.do',
            '/kr/department/cancerClinic/:path1.do',
            '/kr/department/admin/:path1.do',
        ]
    },
    // 게시판
    {
        path: '/kr/department/department/:path1/list.do',
        name: 'dept_board',
        component: () => import('../views/department/board/deptBoardView.vue'),
        alias: [
            '/kr/department/center/:path1/list.do',
            '/kr/department/cancerTeam/:path1/list.do',
            '/kr/department/cancerClinic/:path1/list.do',
            '/kr/department/admin/:path1/list.do',
        ]
    },
    // 게시판 상세 페이지
    {
        path: '/kr/department/department/:path1/view.do',
        name: 'dept_boardDetail',
        component: () => import('../views/department/board/deptBoardDetailView.vue'),
        alias: [
            '/kr/department/center/:path1/view.do',
            '/kr/department/cancerTeam/:path1/view.do',
            '/kr/department/cancerClinic/:path1/view.do',
            '/kr/department/admin/:path1/view.do',
        ]
    },

    // 예상비용찾기
    {
        path: '/:lang/estimate/:path1.do',
        name: 'findEstimatedCosts',
        component: () => import('../views/estimate/FindEstimatedCosts.vue'),

        children: [{
            path: '/:lang/estimate/:path1/:path2.do',
            component: () => import('../views/estimate/FindEstimatedCosts.vue')
        }]
    },

    //다국어 진료예약
    {
        path: '/:lang/appointment/medical-appointment.do',
        name: 'medical-appointment',
        component: () => import('../views/appointment/medicalAppointmentView.vue')
    },
    //다른 의사의 소견
    {
        path: '/:lang/appointment/secondOpinion.do',
        name: 'second-opinion',
        component: () => import('../views/appointment/secondOpinionView.vue')
    },

    // fellowship Programs
    {
        path: '/en/fellowship/international/programs.do',
        name: 'fellowship-programs',
        component: () => import('../views/fellowship/FellowshipProgramsView.vue')
    },
    // apply now
    {
        path: '/en/fellowship/international/programs/:path1/applyNow.do',
        name: 'fellowship-apply',
        component: () => import('../views/fellowship/ApplyNowView.vue')
    },
    // specialized Programs
    {
        path: '/en/fellowship/specializedPrograms.do',
        name: 'fellowship-specializedPrograms',
        component: () => import('../views/fellowship/SpecializedProgramsView.vue')
    },
    
    // 편리한길찾기
    {
        path: '/:lang/navigate/navigate.do',
        name: 'navigate',
        component: () => import('../views/navigate/Navigate.vue'),
    },
    // 편리한길찾기_키오스크용(안암)
    {
        path: '/:lang/navigate/navigateKIOSKAA.do',
        name: 'navigate-kiosk',
        component: () => import('../views/navigate/NavigateKIOSKAA.vue'),
    },

    // QR 코드
    {
        path: '/kr/qrCode.do',
        name: 'QR_Code',
        component: () => import('../views/qr/QRView.vue'),
    },


    // 전화번호부
    {
        path: '/kr/phoneBook.do',
        name: 'phone_book',
        component: () => import('../views/phone/PhoneBookView.vue'),
    },
    
    {
        path: '/qrError.do',
        name: 'QR_Error',
        component: () => import('../views/qr/QRError.vue'),
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: () => import('../views/error/Error.vue'),
        alias: [
            '/main/index.do',
            '/error.do',
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
